import { createSelector } from 'reselect';

import { GlobalState } from 'src/redux/global/reducer';

import { getGlobal } from './state-slicing-selectors';

// These selectors are for features that come from the global state emitted by core
// (get_global_state() in bitbucket/apps/singlepage/views.py)
//
// For normal LD feature evaluation, use useFeature instead.

export const getFeatures = createSelector(getGlobal, (state: GlobalState) =>
  state && state.features ? state.features : {}
);

export const getFeature = (feature: string) =>
  createSelector(getFeatures, features => !!features[feature]);

// use this util if you need to check the value of multiple
// feature flags in a single selector
export const getMultiFeatures = (multipleFts: string[]) =>
  createSelector(getFeatures, features => {
    return multipleFts.every(ft => !!features[ft]);
  });

export const getIsShowUpgradePlansBanner = getFeature(
  'show-upgrade-plans-banner'
);

export const getIsRepositoryPageLoadingErrorGuardEnabled = getFeature(
  'fd-repository-page-loading-error-guard'
);

export const getIsSourceTimeoutFixEnabled = getFeature(
  'view-source-filtering-upon-timeout'
);

export const getIsPRPushedEnabled = getFeature('pr-code-push-webhook');

export const getIsCommitExpandEnabled = getFeature('commit-expand-inno');

export const getSyntaxHighlightingEnabled = getFeature('syntax-highlighting');

export const getPrReviewGroupsEnabled = getFeature('pr-review-groups');

// NOTE: this is not a feature, but a user pref value
export const getIsAIEnabledForWorkspace = getFeature('workspace-ai-enabled');

export const getCreateWorkspaceShowRecaptchaEnabled = getFeature(
  'create-workspace-show-recaptcha'
);

export const getRebaseSyncEnabled = getFeature('rebase-sync');

export const getIsPullRequestLabelsEnabled = getFeature('pull-request-labels');

export const getAllCommentsResolvedMergeCheck = getFeature(
  'unresolved-comments-check'
);

export const getIsLazyDiffstatEnabled = getFeature('lazy-diffstat');

export const getSquashFastForwardMergeEnabled = getFeature(
  'squash-fast-forward-merge-strategy'
);
