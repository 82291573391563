import { Selector, createSelector } from 'reselect';

import { Branch } from 'src/components/types';
import {
  createAsyncAction,
  FetchAction,
  hydrateAction,
} from 'src/redux/actions';
import { MergeStrategy } from 'src/redux/pull-request/types';
import { Ref } from 'src/sections/create-branch';
import { LOADING_STATE } from 'src/sections/create-branch/constants';
import {
  BranchPermissions,
  DetailedBranch,
} from 'src/sections/repository/sections/branches/types';
import { getRepository } from 'src/selectors/state-slicing-selectors';
import { SyncStrategy } from 'src/types/pull-request';
import { BucketState } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

export type BranchSelector<T> = Selector<BucketState, T>;

export type BranchSelectorType = {
  loadingState: LOADING_STATE;
  refs: Ref[];
  hasMoreRefs: boolean;
  next?: string;
};

export type BranchCompareState = {
  sourceBranch: DetailedBranch | undefined;
  destinationBranch: DetailedBranch | undefined;
  destRepoName: string;
  defaultDestBranch: Branch | undefined;
  showParentRepo: boolean;
};

export const HYDRATE_BRANCH_DATA = createAsyncAction(
  'repo/HYDRATE_BRANCH_DATA'
);

export const FETCH_BRANCH_OPTIONS = createAsyncAction(
  'branch/FETCH_BRANCH_OPTIONS'
);

export const FETCH_DEST_BRANCH_DATA = createAsyncAction(
  'branch/FETCH_DEST_BRANCH_DATA'
);

export const CHANGE_DEST_BRANCH = 'branch/CHANGE_DEST_BRANCH';
export const UNLOAD_BRANCH_STATE = 'branch/UNLOAD_BRANCH_STATE';

export const UPDATE_SOURCE_BRANCH_HASH = createAsyncAction(
  'branch/UPDATE_SOURCE_BRANCH_HASH'
);

export const hydrateBranchData = (
  repositoryFullSlug: string,
  branchName: string
): FetchAction =>
  hydrateAction(HYDRATE_BRANCH_DATA, 'repositories.branch', {
    url: `/${repositoryFullSlug}/branch/${branchName}`,
  });

export const initialState: BranchCompareState = {
  sourceBranch: undefined,
  destinationBranch: undefined,
  destRepoName: '',
  defaultDestBranch: undefined,
  showParentRepo: false,
};

type BranchDetailedData = {
  type: string;
  payload: {
    destination: {
      permissions: BranchPermissions;
      isMainBranch: boolean;
      merge_strategies: MergeStrategy[];
      default_merge_strategy: MergeStrategy;
      sync_strategies?: SyncStrategy[];
    };
  };
};

type ChangeDestBranchAction = {
  type: string;
  payload: {
    ref: Ref;
    repo: string;
  };
};
export const branchReducer = createReducer(initialState, {
  [HYDRATE_BRANCH_DATA.SUCCESS](state, action): BranchCompareState {
    const source = {
      isMainBranch: action.payload.source_branch.ismainbranch,
      permissions: action.payload.source_branch.permissions,
      name: action.payload.source_branch.name,
      links: action.payload.source_branch.links,
      target: action.payload.source_branch.target,
    };
    return {
      ...state,
      destRepoName: action.payload.dest_repo,
      sourceBranch: source as DetailedBranch,
      destinationBranch: action.payload.dest_branch,
      defaultDestBranch: action.payload.dest_branch,
      showParentRepo: action.payload.can_access_parent_repo,
    };
  },
  [CHANGE_DEST_BRANCH](
    state: BranchCompareState,
    action: ChangeDestBranchAction
  ): BranchCompareState {
    const { ref, repo } = action.payload;

    return {
      ...state,
      destRepoName: repo,
      destinationBranch: {
        name: ref.name,
        target: {
          type: 'commit',
          hash: ref.hash,
        },
      } as DetailedBranch,
    };
  },
  [UPDATE_SOURCE_BRANCH_HASH.SUCCESS](
    state: BranchCompareState,
    action
  ): BranchCompareState {
    const { sourceBranchHash, targetLinksSelf, targetLinksHtml } =
      action.payload;

    const branch = {
      ...state.sourceBranch,
      target: {
        ...state.sourceBranch!.target,
        hash: sourceBranchHash,
        links: {
          ...state.sourceBranch!.target.links,
          self: targetLinksSelf,
          html: targetLinksHtml,
        },
      },
    };
    return { ...state, sourceBranch: branch as DetailedBranch };
  },
  [FETCH_DEST_BRANCH_DATA.SUCCESS](
    state: BranchCompareState,
    action: BranchDetailedData
  ): BranchCompareState {
    const { destination } = action.payload;
    const destinationBranch = {
      ...state.destinationBranch,
      permissions: destination.permissions,
      isMainBranch: destination.isMainBranch,
      merge_strategies: destination.merge_strategies,
      default_merge_strategy: destination.default_merge_strategy,
      sync_strategies: destination.sync_strategies,
    };
    return {
      ...state,
      destinationBranch: destinationBranch as DetailedBranch,
    };
  },
  [UNLOAD_BRANCH_STATE]() {
    return initialState;
  },
});

export const getBranchCanViewParentRepo: BranchSelector<boolean> =
  createSelector(getRepository, state => state.branch.showParentRepo);

export const getDefaultDestBranchName: BranchSelector<
  string | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.defaultDestBranch?.name
);

export const getBranchViewDestinationBranchName: BranchSelector<
  string | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.destinationBranch?.name
);

export const getBranchViewDestinationBranchHash: BranchSelector<
  string | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.destinationBranch?.target.hash
);

export const getBranchViewDestinationRepoName: BranchSelector<
  string | null | undefined
> = createSelector(getRepository, state => state.branch.destRepoName);

export const getBranchViewDestinationPermissions: BranchSelector<
  BranchPermissions | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.destinationBranch?.permissions
);

export const getBranchViewSourceBranchHash: BranchSelector<
  string | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.sourceBranch?.target.hash
);

export const getBranchViewSourceBranchName: BranchSelector<
  string | null | undefined
> = createSelector(getRepository, state => state.branch.sourceBranch?.name);

export const getBranchViewSourceBranchIsMainBranch: BranchSelector<
  boolean | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.sourceBranch?.isMainBranch
);

export const getBranchViewSourceBranchPermissions: BranchSelector<
  BranchPermissions | null | undefined
> = createSelector(
  getRepository,
  state => state.branch.sourceBranch?.permissions
);

export const getBranchViewDestinationBranch: BranchSelector<
  DetailedBranch | undefined
> = createSelector(getRepository, state => state.branch.destinationBranch);

export const getBranchViewSourceBranch: BranchSelector<
  DetailedBranch | undefined
> = createSelector(getRepository, state => state.branch.sourceBranch);

export const getBranchViewSyncStrategies: BranchSelector<
  SyncStrategy[] | undefined
> = createSelector(
  getBranchViewSourceBranch,
  branch => branch?.sync_strategies
);
